import { create } from 'zustand';
import { GatewayDto } from '../hc-api/api';
import { useErrorsStore } from '../errors/ErrorsStore';
import { gatewaysApi } from '../hc-api';

export type DescriptionUpdateInput = {
  description?: string | null;
};

export interface GatewayStore {
  gatewayId: string | null;
  gateway: GatewayDto | null;

  isLoading: boolean;
  isSaving: boolean;

  setGatewayId: (gatewayId: string | null) => void;
  setGateway: (gateway: GatewayDto | null) => void;
  updateGateway: (input: DescriptionUpdateInput) => void;

  refresh: () => void;

  reset: () => void;
}

export const useGatewayStore = create<GatewayStore>()((set, get) => ({
  gatewayId: null,
  gateway: null,
  isLoading: false,
  isSaving: false,

  setGatewayId: (gatewayId: string | null) => {
    if (gatewayId === get().gatewayId) {
      return;
    }

    if (!gatewayId) {
      get().reset();
    } else {
      set({ gatewayId: gatewayId, gateway: null });
      get().refresh();
    }
  },

  setGateway: (gateway: GatewayDto | null) => {
    set({
      gatewayId: gateway?.id ?? null,
      gateway: gateway,
      isLoading: false,
    });
  },

  updateGateway: async (input: DescriptionUpdateInput) => {
    if (get().isSaving) {
      return;
    }

    try {
      const gatewayId = get().gatewayId;
      if (!gatewayId) {
        return;
      }

      set({ isSaving: true });
      const gateway = await gatewaysApi.patchGateway(gatewayId, input);
      if (gatewayId !== get().gatewayId) {
        return;
      }
      set({ gateway: gateway.data });
    } catch (e: unknown) {
      console.error('failed to save gateway', e);
      useErrorsStore.getState().add(`Failed to save gateway: ${e}`);
    } finally {
      set({ isSaving: false });
    }
  },

  refresh: async () => {
    if (get().isLoading) {
      return;
    }

    if (!get().gatewayId) {
      return;
    }

    set({ isLoading: true });

    try {
      let done = false;
      while (!done) {
        const gatewayId = get().gatewayId;
        if (!gatewayId) {
          return;
        }

        const response = await gatewaysApi.getGateway(gatewayId);
        const gateway = response.data;

        if (!get().isLoading) {
          return;
        }

        if (gateway.id === gatewayId) {
          done = true;
          set({ gateway: gateway });
        }
      }
    } catch (e: unknown) {
      console.log('failed to load gateway', e);
      useErrorsStore.getState().add(`Failed to load gateway: ${e}`);
    } finally {
      set({ isLoading: false });
    }
  },

  reset: () => {
    set({
      isLoading: false,
      isSaving: false,
      gateway: null,
      gatewayId: null,
    });
  },
}));
