import { ActionLink } from '../common/button/ActionLink';
import { useAuthStore } from '../../model/auth/AuthStore';

export function Header() {
  const logout = useAuthStore((store) => () => store.setToken(null));

  return (
    <header className="flex flex-wrap justify-between gap-2 rounded-b-xl bg-blue-800 p-5 font-bold text-white">
      <ActionLink to="/">HC API Web</ActionLink>
      <ActionLink to="/" onClick={logout}>
        Logout
      </ActionLink>
    </header>
  );
}
